import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { fade, lighten } from '@material-ui/core/styles';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid, Cell,
  Label,
} from 'recharts';
import { isQuantitative } from '../../utils/stats';
import {
  MULTIPLE_CHOICES, UNIQUE_CHOICE,
} from '../../constants';
import { nsOptions } from '../../i18n';
import FormattedValue from '../../utils/FormattedValue';
import TextUtil from '../../utils/TextUtil';

export const CHART_HEIGHT = 400;
const UNIT_FONT_SIZE_PX = 12;
const LEGEND_FONT_SIZE_PX = 12;
const CHART_LEGEND_LABEL_MAX_LENGTH = 18;
const PIE_CHART_MAX_LABELS = 20;
const PIE_CHART_MIN_PERCENT_SHOWN = 0.025;
const BAR_CHART_MAX_LABELS = 30;
const RADIAN = Math.PI / 180;

const polarToCartesian = (cx, cy, radius, angle) => ({
  x: cx + Math.cos(-RADIAN * angle) * radius,
  y: cy + Math.sin(-RADIAN * angle) * radius,
});

const styles = (theme) => ({
  chart: {
    fill: theme.palette.text.secondary,
  },
  unit: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(UNIT_FONT_SIZE_PX),
  },
});

@withTranslation('', nsOptions)
@withStyles(styles, { withTheme: true })
class Chart extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string.isRequired,
    unit: PropTypes.string,
    seriesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    count: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setActive: PropTypes.func.isRequired,
    unsetActive: PropTypes.func.isRequired,
    activeIndex: PropTypes.number,
    activeSeriesName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    activeClick: PropTypes.bool.isRequired,
    theme: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
    inModule: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    unit: '',
    activeIndex: null,
    activeSeriesName: '',
  };

  onClick(event, index, seriesName) {
    event.stopPropagation();
    this.props.setActive(index, seriesName, true);
  }

  getPieLabel = (row) => {
    const {
      x, y, textAnchor, percent,
    } = row;
    return this.isPieLabelShowable(row) ? (
      <text x={x} y={y} textAnchor={textAnchor}>
        <FormattedValue value={percent} isRatio />
      </text>
    ) : <span />;
  };

  getPieLabelLine = (row) => {
    if (!this.isPieLabelShowable(row)) return null;
    const offsetRadius = 20;
    const midAngle = (row.startAngle + row.endAngle) / 2;
    const endPoint = polarToCartesian(
      row.cx, row.cy, row.outerRadius + offsetRadius, midAngle,
    );
    return Pie.renderLabelLineItem(null, {
      fill: 'none',
      stroke: this.props.theme.palette.text.secondary,
      points: [polarToCartesian(row.cx, row.cy, row.outerRadius, midAngle), endPoint],
      key: 'line',
    });
  }

  getSeriesFill(index, seriesName, weight) {
    const {
      theme, activeIndex, activeSeriesName, activeClick,
    } = this.props;
    const baseColor = theme.palette.primary.main;
    const color = lighten(baseColor, weight);

    if (!activeSeriesName) {
      return color;
    }
    if ((index === activeIndex) && (seriesName === activeSeriesName)) {
      return baseColor;
    }

    const opacity = activeClick ? 0.2 : 0.5;
    return fade(color, opacity);
  }

  isPieLabelShowable = (row) => {
    const { percent } = row;
    return percent >= PIE_CHART_MIN_PERCENT_SHOWN;
  }

  formatLegend = (value) => {
    const { t } = this.props;
    return (
      <span style={{
        color: this.props.theme.palette.text.secondary,
        fontSize: this.props.theme.typography.pxToRem(LEGEND_FONT_SIZE_PX),
      }}
      >
        {value ? (
          TextUtil.truncate(value, CHART_LEGEND_LABEL_MAX_LENGTH)
        ) : (
          t('common:elements.default-labels.answer')
        )}
      </span>
    );
  }

  render() {
    const {
      type, unit, classes, theme, setActive, unsetActive, t, inModule,
    } = this.props;
    let { seriesNames } = this.props;
    let newData = [...this.props.data];
    let isStacked = seriesNames.length > 1;
    let chart = null;

    if (type === UNIQUE_CHOICE && seriesNames.length === 1) {
      // Only display modalities with value !== 0 in the chart and the legend
      newData = newData.filter((data) => data.value !== 0);
      const maxWidth = 150;
      const showLegend = newData.length <= PIE_CHART_MAX_LABELS;
      chart = (
        <PieChart>
          {showLegend && <Legend formatter={this.formatLegend} />}
          {seriesNames.map((seriesName, seriesIndex) => {
            const width = Math.min(
              (maxWidth / 2) / seriesNames.length,
              maxWidth / 3,
            );
            const ratio = 1 - seriesIndex / seriesNames.length;
            const outerRadius = maxWidth * ratio;
            const innerRadius = outerRadius - width;
            return (
              <Pie
                key={seriesName}
                data={newData}
                dataKey={seriesName}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                label={seriesIndex === 0 ? this.getPieLabel : null}
                labelLine={seriesIndex === 0 ? this.getPieLabelLine : null}
                isAnimationActive={false}
                startAngle={90}
                endAngle={-270}
              >
                {newData.map((row, index) => (
                  <Cell
                    key={row.name}
                    fill={this.getSeriesFill(
                      index, seriesName, index / newData.length,
                    )}
                    onMouseEnter={() => setActive(index, seriesName, false)}
                    onMouseLeave={() => unsetActive()}
                    onClick={(event) => this.onClick(event, index, seriesName)}
                    cursor="pointer"
                  />
                ))}
              </Pie>
            );
          })}
        </PieChart>
      );
    } else if (
      (type === UNIQUE_CHOICE && seriesNames.length > 1)
        || type === MULTIPLE_CHOICES || isQuantitative(type)
    ) {
      let showLegend = false;
      const kwargs = {};
      if (type === MULTIPLE_CHOICES) {
        kwargs.maxBarSize = 30;
      } else if (isQuantitative(type)) {
        kwargs.barCategoryGap = 0;
        kwargs.barGap = 0;
        isStacked = false;
      }
      if (seriesNames.length > 1 || inModule) {
        newData = newData.filter((data) => data.value !== 0);
        showLegend = isStacked && newData.length <= BAR_CHART_MAX_LABELS;
        // Swaps index levels.
        newData = seriesNames.map((seriesName) => {
          const seriesRow = { name: seriesName };
          const newSeriesNames = [];
          newData.forEach((row) => {
            seriesRow[row.name] = row[seriesName] || 0;
            newSeriesNames.push(row.name);
          });
          seriesNames = newSeriesNames;
          return seriesRow;
        });
      }
      const stroke = theme.palette.text.secondary;
      const showUnit = unit && seriesNames.length === 1;
      const getYAxisMax = (dataMax) => {
        // Increment max by one if peer is an odd number excepted if equl to 1
        if (dataMax === 1) return 1;
        return dataMax + (dataMax % 2 ? 1 : 0);
      };
      chart = (
        <BarChart
          data={newData}
          {...kwargs}
        >
          {showLegend ? <Legend formatter={this.formatLegend} /> : null}
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            height={30 + (showUnit ? UNIT_FONT_SIZE_PX * 1.5 : 0)}
          >
            {
              showUnit ? (
                <Label className={classes.unit} value={unit} position="insideBottom" />
              ) : null
            }
          </XAxis>
          <YAxis
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            domain={[0, getYAxisMax]}
          >
            <Label
              className={classes.unit}
              value={t('stats:count-unit')}
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <CartesianGrid vertical={false} horizontal={{ stroke }} />
          {seriesNames.map((seriesName, index) => {
            const weight = isStacked ? index / seriesNames.length : 0;
            return (
              <Bar
                key={seriesName}
                dataKey={seriesName}
                stackId={isStacked ? '' : null}
                animationDuration={300}
                fill={this.getSeriesFill(-1, seriesName, weight)}
              >
                {newData.map((row, rowIndex) => (
                  <Cell
                    key={row.name}
                    onMouseEnter={() => setActive(rowIndex, seriesName, false)}
                    onMouseLeave={() => unsetActive()}
                    onClick={(event) => this.onClick(event, rowIndex, seriesName)}
                    fill={this.getSeriesFill(rowIndex, seriesName, weight)}
                    cursor="pointer"
                  />
                ))}
              </Bar>
            );
          })}
        </BarChart>
      );
    }
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={classes.chart} onClick={() => unsetActive(true)}>
        <ResponsiveContainer width="100%" height={CHART_HEIGHT}>{chart}</ResponsiveContainer>
      </div>
    );
  }
}


export default Chart;
