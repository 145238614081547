import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import {
  Link, Route, Switch, Redirect,
} from 'react-router-dom';
import {
  Collapse, Nav, Navbar, NavbarBrand, NavItem, NavLink, NavbarToggler,
} from 'reactstrap';
import api from '../api';
import '../assets/css/dashboard.css';
import logo from '../assets/img/light_logo_r.png';
import {
  INSTITUTION_TYPES, USER_TYPE_PRACTITIONER, USER_TYPE_HEALTHCARE_FACILITY,
} from '../constants';
import BrowserView, { isBrowserView } from '../components/BrowserView';
import Footer from '../components/Footer';
import MobileView, { isMobileView } from '../components/MobileView';
import { isMixedView } from '../components/MixedView';
import NewDropdownMenu from '../components/NewDropdownMenu';
import TabletView from '../components/TabletView';
import Page, { checkAuth } from '../components/Page';
import { UserAvatar } from '../components/avatar';
import NewTooltip from '../components/NewTooltip';
import { isAdmin, isVerified } from '../utils/data-util';
import { nsOptions } from '../i18n';
import Dashboard from './Dashboard';
import JoinProject from './JoinProject';
import JoinTeam from './JoinTeam';
import ProjectList from './ProjectList';
import ProjectView from './ProjectView';
import Settings from './Settings';
import Teams, { TeamPage } from './Teams';
import LicenseProducts from './LicenseProducts';
import UnderVerification from './UnderVerification';
import NotFound from './NotFound';
import Community from './Community';
import FreeTrialModal from '../components/FreeTrialModal';
import { logout } from '../utils/connection';
import { getFAQUrl } from '../utils/urls';
import TextUtil from '../utils/TextUtil';
import ErrorUtil from '../utils/ErrorUtil';
import canRequestFreeTrial from '../utils/free-trial';
import { childrenPropTypes } from '../utils/generic-prop-types';
import UserUtil from '../utils/UserUtil';
import UnsubscribeNotification from './UnsubscribeNotification';

@withToastManager
@withTranslation('', nsOptions)
class UserMenu extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    url: PropTypes.string.isRequired,
  };

  getLabel = () => {
    const { user } = this.props;
    if (INSTITUTION_TYPES.includes(user.type)) {
      return TextUtil.truncate(user.institution, 20);
    }
    return `${TextUtil.truncate(user.first_name, 18)} ${user.last_name.substring(0, 1)}.`;
  };

  render() {
    const {
      t, user, url,
    } = this.props;

    const { verified } = user;
    const isPractitioner = user && user.type === USER_TYPE_PRACTITIONER;

    const canOwnLicenceProducts = user && user.limitations.can_own_licence_products;

    return (
      <NewDropdownMenu
        type={isMobileView() ? 'dropleft' : 'dropdown'}
        menuClass="dropdown-menu-right"
        triggerElement={(
          <button
            className="nav-link dropdown-toggle no-button-style menu-account-block d-flex align-items-center"
            id="navbarDropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <UserAvatar
              user={user}
              className="mr-2"
            />
            <div className="d-inline-block">
              {this.getLabel()}
              {
                isMixedView() && (
                  <FontAwesomeIcon
                    icon={['far', 'angle-down']}
                    className="ml-2"
                  />
                )
              }
            </div>
          </button>
        )}
      >
        <Link
          className="dropdown-item navigation-menu-item"
          to={`${url}/settings/account`}
        >
          <FontAwesomeIcon
            icon={['fal', 'user']}
            className="navigation-menu-icon fa-fw"
          />
          {t('user:nav.account')}
        </Link>
        {
          verified && !canOwnLicenceProducts
            && (user.limitations.can_create_team || user.limitations.can_join_team)
            && (
              <Link
                className="dropdown-item navigation-menu-item"
                to={`${url}/settings/teams${isPractitioner ? '/my-team' : ''}`}
              >
                <FontAwesomeIcon
                  icon={['fal', 'users']}
                  className="navigation-menu-icon fa-fw"
                />
                {t('common:nav.teams', { context: 'plural_unknown' })}
              </Link>
            )
        }
        {verified && isBrowserView() && (
          <Link
            className="dropdown-item navigation-menu-item"
            to={`${url}/settings/uploads`}
          >
            <FontAwesomeIcon
              icon={['fal', 'file-upload']}
              className="navigation-menu-icon fa-fw"
            />
            {t('user:nav.spreadsheets-import')}
          </Link>
        )}
        {!canOwnLicenceProducts && (
          <Link
            className="dropdown-item navigation-menu-item"
            to={`${url}/settings/license`}
          >
            <FontAwesomeIcon
              icon={['fal', 'credit-card']}
              className="navigation-menu-icon fa-fw"
            />
            {t(`user:nav.${isPractitioner ? 'licenses' : 'license'}`)}
          </Link>
        )}
        {
          isAdmin(user) && (
            <>
              <div className="dropdown-divider" />
              <Link
                className="dropdown-item navigation-menu-item bg-red text-white"
                to="/admin/home"
              >
                <FontAwesomeIcon
                  icon={['fal', 'user-crown']}
                  className="navigation-menu-icon fa-fw"
                />
                {t('common:nav.control-panel')}
              </Link>
            </>
          )
        }
        <div className="dropdown-divider" />
        <button
          className="dropdown-item"
          onClick={() => logout(this.props)}
        >
          <FontAwesomeIcon
            icon={['fal', 'sign-out-alt']}
            className="navigation-menu-icon fa-fw"
          />
          {t('common:nav.logout')}
        </button>
      </NewDropdownMenu>
    );
  }
}


// eslint-disable-next-line react/prefer-stateless-function
class FAQLink extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  };

  render() {
    const { t, language } = this.props;
    return (
      <NavLink
        className="nav-link h-100"
        href={getFAQUrl(language)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <NewTooltip
          content={t('common:discover-features')}
        >
          <FontAwesomeIcon
            className="mt-2"
            icon={['fal', 'question-circle']}
            transform="grow-4"
          />
        </NewTooltip>
      </NavLink>
    );
  }
}

function FreeTrialButton(props) {
  const { t } = props;

  return (
    <FreeTrialModal>
      <NewTooltip
        content={(
          <div>
            <div>
              {t('common:nav.free-trial-tooltip')}
            </div>
            <div className="small text-gray-light pt-1">
              {t('common:nav.free-trial-learn-more')}
            </div>
          </div>
        )}
      >
        <button
          className="btn btn-free-trial"
        >
          <FontAwesomeIcon
            icon={['fad', 'gift']}
            className="mr-2 fa-swap-opacity"
            transform="grow-4"
          />
          <div className="px-1">
            {t('common:nav.free-trial')}
          </div>
        </button>
      </NewTooltip>
    </FreeTrialModal>
  );
}

FreeTrialButton.propTypes = {
  t: PropTypes.func.isRequired,
};

function MenuItem(props) {
  const {
    link, url, tooltipContent, iconType, iconName, iconTransform, children, getLinkState,
    getEffectiveLink, show,
  } = props;

  return show ? (
    <NewTooltip
      content={tooltipContent || ''}
      disabled={!tooltipContent}
    >
      <NavItem className={getLinkState(link)}>
        <Link className="nav-link" to={getEffectiveLink(url)}>
          <div className="my-0 tab-span d-flex align-items-center">
            <div>
              <FontAwesomeIcon
                icon={[iconType, iconName]}
                className="nav-icon"
                transform={iconTransform || ''}
              />
            </div>
            <div>
              {children}
            </div>
          </div>
        </Link>
      </NavItem>
    </NewTooltip>
  ) : null;
}

MenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  show: PropTypes.bool,
  tooltipContent: PropTypes.string,
  iconType: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  iconTransform: PropTypes.string,
  children: childrenPropTypes().isRequired,
  getLinkState: PropTypes.func,
  getEffectiveLink: PropTypes.func,
};

MenuItem.defaultProps = {
  show: true,
  tooltipContent: null,
  iconType: 'fal',
  iconTransform: null,
  getLinkState: () => {},
  getEffectiveLink: () => {},
};

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
});

@connect(mapStateToProps)
@withTranslation('', nsOptions)
class DashboardLayout extends Component {
  static propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape(),
    admin: PropTypes.bool,
  };

  static defaultProps = {
    user: undefined,
    admin: false,
  };

  static handleOutsideClick(e) {
    if (e.persist) e.persist();
    NewDropdownMenu.handleOutsideClick(e);
    // NewTooltip.handleOutsideClick(e);
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      displayFreeTrialButton: false,
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.setState({
        isOpen: false,
      });
    });
    document.getElementById('body').className = 'dashboard-body';
    document.addEventListener('mousedown', DashboardLayout.handleOutsideClick);
    const { user } = this.props;
    if (user) {
      this.fetchUserTeams();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && prevProps.user !== this.props.user) {
      this.fetchUserTeams();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', DashboardLayout.handleOutsideClick);
    this.unlisten();
  }

  fetchUserTeams = async () => {
    const { user } = this.props;
    if (user) {
      try {
        const res = await api.list('teams', {
          members__user: user.id,
        }, { pagination: 'no' });
        const displayFreeTrialButton = canRequestFreeTrial(user, res);
        await this.setState({ displayFreeTrialButton });
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  };

  getEffectiveLink = (link) => {
    const { user, match } = this.props;
    if (!isVerified(user)) {
      return '/';
    }
    return match.url + link;
  };

  getLinkState = (originalUrl) => {
    let state = '';
    const url = `${this.props.match.url}/${originalUrl}`;
    if (this.props.location.pathname.match(new RegExp(url))) {
      state += ' active';
    }
    return state;
  };

  toggleNavbar = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const {
      t, user, match, i18n, history,
    } = this.props;
    const { displayFreeTrialButton } = this.state;
    const { url } = match;

    // User limitations
    const canHavePublicProfile = user ? user.limitations.can_have_a_public_profile : false;
    const showLicensesAndTeams = user ? user.limitations.can_own_licence_products : false;
    const showDashboard = user ? user.type !== USER_TYPE_HEALTHCARE_FACILITY : true;

    const MenuItemDashboard = (props) => (
      <MenuItem
        link="home"
        url="/home/projects"
        iconName="home"
        iconTransform="grow-3"
        getLinkState={this.getLinkState}
        getEffectiveLink={this.getEffectiveLink}
        {...props}
      >
        {t('common:nav.dashboard')}
      </MenuItem>
    );

    const MenuItemProjects = () => (
      <MenuItem
        link="project(s?/?.*)"
        url="/projects"
        iconName="ballot-check"
        tooltipContent={isBrowserView() ? t(`common:nav-tooltips.projects${canHavePublicProfile ? '-public-profile' : ''}`) : ''}
        getLinkState={this.getLinkState}
        getEffectiveLink={this.getEffectiveLink}
      >
        {t('common:nav.projects')}
      </MenuItem>
    );

    const MenuItemCommunity = () => (
      <MenuItem
        link="community"
        url={`/community/${canHavePublicProfile ? 'my-profile' : 'profiles'}`}
        iconName="chart-network"
        iconTransform="grow-1 down-1"
        tooltipContent={isBrowserView() ? t(`common:nav-tooltips.community${canHavePublicProfile ? '-public-profile' : ''}`) : ''}
        getLinkState={this.getLinkState}
        getEffectiveLink={this.getEffectiveLink}
      >
        {t(`common:nav.community${canHavePublicProfile ? '-public-profile' : ''}`)}
      </MenuItem>
    );

    const MenuItemLicenses = (props) => (
      <MenuItem
        link="licensed-products"
        url="/licensed-products"
        iconName="list"
        iconTransform="down-1"
        getLinkState={this.getLinkState}
        getEffectiveLink={this.getEffectiveLink}
        {...props}
      >
        {t('common:nav.licenses')}
      </MenuItem>
    );

    const MenuItemTeams = (props) => (
      <MenuItem
        link="team(s?/?.*)"
        url="/teams"
        iconName="users"
        getLinkState={this.getLinkState}
        getEffectiveLink={this.getEffectiveLink}
        {...props}
      >
        {t('common:nav.teams')}
      </MenuItem>
    );

    const MenuItemFAQ = () => (
      <li className="nav-item pl-0 ml-0">
        <FAQLink t={t} language={i18n.language} />
      </li>
    );

    const MenuItemFreeTrial = () => (displayFreeTrialButton && user && !user.disable_free_trial ? (
      <li className="nav-item pl-0 ml-0 d-flex align-items-center">
        <FreeTrialButton t={t} />
      </li>
    ) : null);

    const MenuItemUser = () => {
      const liProps = {
        className: 'nav-item dropdown test-button',
      };
      if (isMobileView()) {
        liProps.className = `${liProps.className} account-tab-list-item`;
      }
      return (
        <li {...liProps}>
          <UserMenu user={user} url={url} history={history} />
        </li>
      );
    };

    const homeLink = `/${UserUtil.getUserHome(user)}`;

    return (
      <Page {...this.props} checkAuthorizations={[checkAuth]}>
        {
          user && (
            <>
              <Navbar
                light
                expand="xl"
                id="main-navbar"
                className={isBrowserView() ? 'browser-navbar' : ''}
              >
                <NavbarBrand
                  href={this.getEffectiveLink(homeLink)}
                >
                  <img src={logo} className="" alt="" height="28" />
                </NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className="pr-4 pb-3">
                  <FontAwesomeIcon
                    icon={['far', 'bars']}
                    transform="grow-4"
                    className="text-white"
                  />
                </NavbarToggler>
                <Collapse isOpen={this.state.isOpen} navbar>
                  <BrowserView>
                    <Nav id="left-navbar" className="mr-auto browser-left-navbar" navbar>
                      <MenuItemDashboard show={showDashboard} />
                      <MenuItemProjects />
                      <MenuItemTeams show={showLicensesAndTeams} />
                      <MenuItemLicenses show={showLicensesAndTeams} />
                      <MenuItemCommunity />
                    </Nav>
                    <Nav navbar>
                      <MenuItemFreeTrial />
                      <MenuItemFAQ />
                      <MenuItemUser />
                    </Nav>
                  </BrowserView>
                  <MobileView>
                    <Nav id="left-navbar" className="mr-auto">
                      <div className="row justify-content-end mx-0 navmenu-tabs-row">
                        {showDashboard && (
                          <div className="col-6 navmenu-item-col px-0">
                            <MenuItemDashboard show={showDashboard} />
                          </div>
                        )}
                        <div className="col-6 navmenu-item-col px-0">
                          <MenuItemProjects />
                        </div>
                        <div className="col-6 navmenu-item-col px-0">
                          <MenuItemCommunity />
                        </div>
                        <div className="col-6 navmenu-item-col px-0 py-3 d-flex align-items-center">
                          <MenuItemUser />
                        </div>
                      </div>
                    </Nav>
                  </MobileView>
                  <TabletView>
                    <Nav id="left-navbar" className="mr-auto">
                      <div className="row" style={{ width: '100vw' }}>
                        {showDashboard && (
                          <div className="col-3 d-flex align-items-center px-0">
                            <MenuItemDashboard show={showDashboard} />
                          </div>
                        )}
                        <div className="col-3 d-flex align-items-center px-0">
                          <MenuItemProjects />
                        </div>
                        <div className="col-3 d-flex align-items-center px-0">
                          <MenuItemCommunity />
                        </div>
                        <div className="col-3 d-flex align-items-center pl-4 pb-1">
                          <MenuItemUser />
                        </div>
                      </div>
                    </Nav>
                  </TabletView>
                </Collapse>
              </Navbar>
              <div>
                <Switch>
                  {showDashboard && (
                    <Route
                      {...this.props}
                      path={`${url}/home`}
                      component={Dashboard}
                    />
                  )}
                  <Redirect from="/dashboard/home" to={`${url}/projects`} />
                  <Route
                    {...this.props}
                    path={`${url}/under-verification`}
                    component={UnderVerification}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/projects`}
                    component={ProjectList}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/project/:id`}
                    component={ProjectView}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/join-project/:token`}
                    component={JoinProject}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/join-team/:token`}
                    component={JoinTeam}
                  />
                  {showLicensesAndTeams && (
                    <Route
                      {...this.props}
                      path={`${url}/licensed-products`}
                      component={LicenseProducts}
                    />
                  )}
                  {showLicensesAndTeams && (
                    <Route
                      {...this.props}
                      path={`${url}/team/:id`}
                      component={TeamPage}
                    />
                  )}
                  {showLicensesAndTeams && (
                    <Route
                      {...this.props}
                      path={`${url}/teams`}
                      component={Teams}
                    />
                  )}
                  <Route
                    {...this.props}
                    path={`${url}/community`}
                    component={Community}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/settings`}
                    component={Settings}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/unsubscribe-notifications/:inclusionId/:notificationId`}
                    component={UnsubscribeNotification}
                  />
                  <Route component={NotFound} {...this.props} />
                </Switch>
              </div>
              <Footer key="dashboard-2" />
            </>
          )
        }
      </Page>
    );
  }
}

export default DashboardLayout;
