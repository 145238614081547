import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import { Button, Label } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'react-fast-compare';
import memoize from 'memoize-one';
import { UpdatedBadge } from '../components/Badges';
import {
  projectsActions, projectUsersActions, elementsActions,
  projectElementsActions, projectPagesActions, elementModalitiesActions, elementLinksActions,
  inclusionsActions, projectEntriesActions, teamsActions,
} from '../redux/actions';
import '../assets/css/loader.css';
// import AdminHead from '../components/AdminHead';
import BigMenu from '../components/BigMenu';
import BigMenuDivider from '../components/BigMenuDivider';
import BigMenuItem from '../components/BigMenuItem';
import BrowserView, { isBrowserView } from '../components/BrowserView';
import MobileView, { isMobileView } from '../components/MobileView';
import MixedView from '../components/MixedView';
import { CardLoader } from '../components/Loader';
import MobileUnsupported from '../components/MobileUnsupported';
import TabletUnsupported from '../components/TabletUnsupported';
import Help from '../components/Help';
import Page from '../components/Page';
import ProjectForms from '../components/ProjectForms';
import ProjectInclusions from '../components/ProjectInclusions';
import ProjectResources from '../components/ProjectResources';
import ProjectResults from '../components/stats/ProjectResults';
import ProjectSettings from '../components/ProjectSettings';
import withLicenseMsgModal from '../components/withLicenseMsgModal';
import NewTooltip from '../components/NewTooltip';
import AuditTrailModal from '../components/AuditTrailModal';
import history from '../history';
import { nsOptions } from '../i18n';
import DomUtil from '../utils/DomUtil';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import { hasPermission, isProjectDisabled } from '../utils/data-util';
import { deObfuscateEmail } from '../utils/email';
import {
  CAN_EDIT_FORM_AND_DOCUMENTATIONS, CAN_VIEW_EXTERNAL_INCLUSIONS, CAN_INVITE_EXTERNAL_TEAM,
  CAN_INCLUDE, PROJECT_ON_HOLD, CAN_CHANGE_PROJECT_STATUS, CAN_VIEW_FORM_AND_DOCUMENTATIONS,
  CAN_INCLUDE_TEST_DATA, PROJECT_USER_OWNER_ONLY, CAN_VIEW_PROJECT_RESULTS, PROJECT_CLOSED,
  PROJECT_IN_PROGRESS, PROJECT_CLOSED_WITH_MODIFICATIONS,
} from '../constants';
import FAQLink from '../components/FAQLink';
import { ProjectPaused, ProjectStatus } from '../components/ProjectStatus';
import ProjectNotifications from '../components/ProjectNotifications';
import api from '../api';

// eslint-disable-next-line react/prefer-stateless-function
@withTranslation('', nsOptions)
class ProjectHead extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    project: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    onShareToExternalTeam: PropTypes.func,
    admin: PropTypes.bool,
  }

  static defaultProps = {
    project: undefined,
    projectUser: undefined,
    onShareToExternalTeam: () => {},
    admin: false,
  }

  constructor(props) {
    super(props);
    this.memoizedGetRoles = memoize(this.getRoles);
    this.state = {
      loadingStats: false,
      errorStats: null,
      centerStats: null,
      // eslint-disable-next-line react/no-unused-state
      userStats: null,
    };
  }

  componentDidMount() {
    this.fetchStatsData();
  }

  componentDidUpdate(prevProps) {
    const { project } = this.props;
    if (
      (!prevProps.project || !prevProps.project.id)
        && project && project.id
    ) {
      this.fetchStatsData();
    }
  }

  fetchStatsData = async () => {
    this.setState({ loadingStats: true, errorStats: null });
    try {
      const { project } = this.props;
      if (!project || !project.id) {
        this.setState({ loadingStats: false });
        return;
      }
      const [centerStats, userStats] = await Promise.all([
        api.requestData(`projects/${project.id}/center-stats`, null, 'post', undefined, {}),
        api.requestData(`projects/${project.id}/user-stats`, null, 'post', undefined, {}),
      ]);

      this.setState({
        centerStats,
        // eslint-disable-next-line react/no-unused-state
        userStats,
        loadingStats: false,
      });
    } catch (error) {
      this.setState({ errorStats: error, loadingStats: false });
    }
  }

  displaySharingButton = () => {
    const {
      user, projectUser, project,
    } = this.props;
    // Check if external participant and if can invite external team
    if (project && user && project.manager.id === user.id) return false;
    if (projectUser && project && hasPermission(projectUser, CAN_INVITE_EXTERNAL_TEAM)) return true;
    return false;
  }

  getRoles = (project, projectUser) => {
    const roles = [];

    if (!project || !projectUser) return roles;

    const isOwner = project.owner.id === projectUser.user.id;
    const isManager = project.manager.id === projectUser.user.id;

    if (isOwner) roles.push('owner');
    if (isManager) {
      roles.push('manager');
    } else if (!isOwner || projectUser.team_members.length > 0) {
      roles.push('participant');
    }

    return roles;
  }

  getInactiveProjectInfo = () => {
    const {
      t, project, user, admin,
    } = this.props;

    if (!project) return null;

    if (!isProjectDisabled(project) && project.is_paused) {
      return <ProjectPaused className="dotted-underline text-uppercase" tooltipTheme="light" />;
    }

    const isManager = project && project.manager.id === user.id;
    const email = deObfuscateEmail('support', 'doqboard.com');

    let tradKey = '';
    let icon = '';
    let className = '';

    if (project.is_disabled_by_admin) {
      tradKey = 'project:deactivated-by-admin';
      icon = 'archive';
      className = 'color-gray-dark';
    } else if (project.is_disabled) {
      tradKey = 'project:deactivated';
      icon = 'archive';
      className = 'text-newyellow-3';
    } else {
      tradKey = 'project:frozen';
      icon = 'exclamation-triangle';
      className = 'text-red';
    }

    let projectDisabledTooltipContent = t('project:frozen.participant-help');
    if (isManager && !admin) {
      projectDisabledTooltipContent = (
        <>
          {t(`${tradKey}.manager-help.part-1`)}
          <br />
          {t(`${tradKey}.manager-help.part-2`, { email })}
        </>
      );
    } else if (admin && project && project.frozen_reason) {
      projectDisabledTooltipContent = (
        <span>
          Reason for deactivation:
          &nbsp;
          {project.frozen_reason}
        </span>
      );
    }

    // Project disabled
    return (
      <span>
        <NewTooltip
          content={projectDisabledTooltipContent}
          theme="light"
        >
          <span className={className}>
            <FontAwesomeIcon
              icon={['fas', icon]}
              transform="grow-2"
              className="project-frozen-icon"
            />
            <span className="ml-3 dotted-underline font-weight-semibold">
              {t(`${tradKey}.info`)}
            </span>
          </span>
        </NewTooltip>
        {
          isManager && (
            <Link
              to="/dashboard/settings/license/"
              className={`small font-italic text-newblue-1${isMobileView() ? ' d-block mt-1' : ' ml-3'}`}
            >
              {t('project:manage-license')}
            </Link>
          )
        }
      </span>
    );
  };

  renderInclusionInfo() {
    const {
      loadingStats, errorStats, centerStats, userStats,
    } = this.state;
    const { t } = this.props;

    if (loadingStats) {
      return <div>{t('project:renderInclusionInfo.loadingStats')}</div>;
    }

    if (errorStats) {
      return (
        <div>
          {t('project:renderInclusionInfo.errorWhileLoading')}
          {errorStats.message}
        </div>
      );
    }

    if (!centerStats) return null;

    const totalInclusions = centerStats.count || 0;
    const breakdownArray = centerStats.center_inclusions_breakdown || [];
    breakdownArray.sort((a, b) => b.count - a.count);
    const averageCompletion = centerStats.average_completion || 0;
    const centerCount = breakdownArray.length;
    const userCount = userStats ? userStats.center_inclusions_breakdown.length : 0;

    return (
      <div className="card mb-2">
        <div className="card-body">
          <div className="row text-center">

            <div className="col-sm-3 mb-3 mb-sm-0">
              <h6 className="text-uppercase text-muted">
                {t('project:renderInclusionInfo.generalInclusionCount')}
              </h6>
              <div className="h4 text-primary mt-1">
                {totalInclusions}
              </div>
            </div>

            <div className="col-sm-3 mb-3 mb-sm-0">
              <h6 className="text-uppercase text-muted">
                {t('project:renderInclusionInfo.centers')}
              </h6>
              <div className="h4 text-primary mt-1">
                {centerCount}
              </div>
            </div>

            <div className="col-sm-3 mb-3 mb-sm-0">
              <h6 className="text-uppercase text-muted">
                {t('project:renderInclusionInfo.users')}
              </h6>
              <div className="h4 text-primary mt-1">
                {userCount}
              </div>
            </div>

            <div className="col-sm-3">
              <h6 className="text-uppercase text-muted">
                {t('project:renderInclusionInfo.averageCompletionRate')}
              </h6>
              <div className="h5 mt-1">
                {averageCompletion.toFixed(0)}
                %
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      t, project, user, onShareToExternalTeam, projectUser,
    } = this.props;
    const isManager = project && project.manager.id === user.id;
    const isOwner = project && project.owner.id === user.id;
    const isOwnerOnly = projectUser && projectUser.type === PROJECT_USER_OWNER_ONLY;

    const showSharingButton = isBrowserView() && this.displaySharingButton();

    const roles = this.memoizedGetRoles(project, projectUser);

    const projectActive = project && !isProjectDisabled(project) && !project.is_paused;

    return (
      <div id="head">
        <div className="row">
          <div className="col-12">
            <div className={`card bg-transparent border-0 pt-5 ${isMobileView() ? 'pb-1' : 'pt-lg-3 pb-4 mt-3'}`}>
              <h2
                className="m-0 d-block font-weight-normal text-primary"
              >
                <span className="project-title">
                  {project ? project.name : ''}
                </span>
                <div className="project-head-info">
                  {
                    Boolean(roles.length) && (
                      <div className={`pr-4 ${isMobileView() ? 'pb-3' : ''}`}>
                        {t(`project:user-type.titles.${roles.join('-and-')}`)}
                        {(isOwner || !isManager) && (
                          <Help
                            iconClassName="ml-2"
                            transform="shrink-3"
                          >
                            {isOwnerOnly ? (
                              <div className="p-2">
                                <div className="mb-1">
                                  {t('project:user-type.owner-help.title')}
                                </div>
                                <ul className="mb-0 owner-rights-list">
                                  {t('project:user-type.owner-help.list', { returnObjects: true })
                                    .map((item) => (
                                      <li
                                        key={item}
                                      >
                                        {item}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            ) : t('project:user-type.generic-help')}
                          </Help>
                        )}
                      </div>
                    )
                  }
                  {!projectActive ? this.getInactiveProjectInfo() : (
                    <ProjectStatus
                      className={`d-flex align-items-center justify-content-start project-status ${project.status !== PROJECT_IN_PROGRESS ? 'dotted' : ''}`}
                      status={project.status}
                      getTooltipContent={(status) => {
                        if (status === PROJECT_IN_PROGRESS) return null;
                        if (status === PROJECT_ON_HOLD) {
                          return t('project:statuses.on_hold-help');
                        }
                        const { closed_status_option: closedStatusOption } = project;
                        return (
                          <span>
                            {t(`project:statuses.${status}`)}
                            {status === PROJECT_CLOSED && (
                              <>
                                {t('common:colon')}
                                <ul className="mb-0">
                                  <li>
                                    {t('project:closed-status-options.no-inclusion-creation')}
                                  </li>
                                  {closedStatusOption && (
                                    <li>
                                      {t(
                                        'project:closed-status-options.modifications',
                                        {
                                          context: closedStatusOption
                                            === PROJECT_CLOSED_WITH_MODIFICATIONS
                                            ? 'with' : 'without',
                                        },
                                      )}
                                    </li>
                                  )}
                                </ul>
                              </>
                            )}
                          </span>
                        );
                      }}
                      tooltipTheme="light"
                    />
                  )}
                </div>
                {
                  showSharingButton && (
                    <span
                      style={{ marginLeft: '2rem' }}
                    >
                      <NewTooltip
                        content={t('project:invite-my-team-to-participate-tooltip')}
                        disabled={isManager}
                        placement="bottom"
                        arrow
                        containerClassName="invite-your-local-team-tooltip"
                      >
                        <Button
                          color="newblue-1"
                          className="text-white"
                          onClick={onShareToExternalTeam}
                          size="sm"
                        >
                          <FontAwesomeIcon
                            icon={['far', 'envelope']}
                            className="mr-2"
                          />
                          {t('project:invite-my-team-to-participate')}
                        </Button>
                      </NewTooltip>
                    </span>
                  )
                }
              </h2>
              {
                !isBrowserView() && (
                  <div className="device-view-unsupported pt-3">
                    {t('common:mobile-and-tablet-unsupported')}
                  </div>
                )
              }
            </div>
          </div>
        </div>
        {this.renderInclusionInfo()}
      </div>
    );
  }
}

const ContentHeader = (props) => {
  const { children, subtitle } = props;
  return (
    <>
      <h4 className="font-weight-normal mb-3">
        {children}
      </h4>
      {subtitle && (
        <div className="page-header-subtitle mb-3">
          {subtitle}
        </div>
      )}
    </>
  );
};

ContentHeader.propTypes = {
  children: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

ContentHeader.defaultProps = {
  subtitle: undefined,
};

const NORMAL_MODE = 'normal';
const TEST_MODE = 'test';

const mapStateToProps = (state, ownProps) => ({
  userId: state.auth.authUser.id,
  user: state.auth.authUser,
  project: state.projects[ownProps.match.params.id],
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user && pUser.user.id === state.auth.authUser.id
      && String(pUser.project) === ownProps.match.params.id
  )),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProject: async () => dispatch(projectsActions.read(ownProps.match.params.id, {
    admin: ownProps.admin,
    annotate_investigators_count: true,
  })),
  patchProject: async (data, params = {}) => dispatch(projectsActions.patch(
    ownProps.match.params.id,
    data,
    { annotate_investigators_count: true, ...params },
  )),
  fetchTeams: async () => dispatch(teamsActions.list({
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  clearPages: () => dispatch(projectPagesActions.removeAllSuccess()),
  clearProjectElements: () => dispatch(projectElementsActions.removeAllSuccess()),
  clearElements: () => dispatch(elementsActions.removeAllSuccess()),
  clearElementModalities: () => dispatch(elementModalitiesActions.removeAllSuccess()),
  clearElementLinks: () => dispatch(elementLinksActions.removeAllSuccess()),
  clearProjectUsers: () => dispatch(projectUsersActions.removeAllSuccess()),
  clearTeams: () => dispatch(teamsActions.removeAllSuccess()),
  clearInclusions: () => dispatch(inclusionsActions.removeAllSuccess()),
  clearProjectEntries: () => dispatch(projectEntriesActions.removeAllSuccess()),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class ProjectView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    admin: PropTypes.bool,
    hideHeader: PropTypes.bool,
    userId: PropTypes.number.isRequired,
    user: PropTypes.shape().isRequired,
    project: PropTypes.shape(),
    projectUser: PropTypes.shape(),
    fetchProject: PropTypes.func.isRequired,
    patchProject: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
    clearPages: PropTypes.func.isRequired,
    clearProjectElements: PropTypes.func.isRequired,
    clearElements: PropTypes.func.isRequired,
    clearElementModalities: PropTypes.func.isRequired,
    clearElementLinks: PropTypes.func.isRequired,
    clearProjectUsers: PropTypes.func.isRequired,
    clearTeams: PropTypes.func.isRequired,
    clearInclusions: PropTypes.func.isRequired,
    clearProjectEntries: PropTypes.func.isRequired,
  };

  static defaultProps = {
    admin: false,
    hideHeader: false,
    project: undefined,
    projectUser: undefined,
  };

  static getInitialMode(props) {
    if (props.project && props.project.status === PROJECT_ON_HOLD) return TEST_MODE;
    if (props.projectUser && props.projectUser.type === PROJECT_USER_OWNER_ONLY) return TEST_MODE;
    return NORMAL_MODE;
  }

  constructor(props) {
    super(props);
    this.state = {
      projectLoading: false,
      scrollToInvitations: false,
      scrollToProjectStatus: false,
      inclusionMode: ProjectView.getInitialMode(props),
      scrollEventCallback: () => {},
    };
    this.timeoutHandler = new TimeoutHandler();
  }

  componentDidMount() {
    this.loadData(true);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      this.reload();
    }
    const { project, projectUser } = this.props;
    if (!isEqual(prevProps.projectUser, projectUser)
      || (prevProps.project && project && prevProps.project.status !== project.status)) {
      this.resetInclusionMode();
    }
  }

  resetInclusionMode = () => {
    const newMode = ProjectView.getInitialMode(this.props);
    this.setState({ inclusionMode: newMode });
  }

  loadData = async (forceProjectLoading = false) => {
    const { location } = this.props;
    if (location.pathname.endsWith('/resources')) {
      // Only resourses page has not its own internal loader
      this.setState({ projectLoading: true });
    }

    const {
      clearPages, clearProjectElements, clearElements, clearElementModalities, clearElementLinks,
      clearProjectUsers, clearTeams, clearInclusions, clearProjectEntries,
    } = this.props;

    clearPages();
    clearProjectElements();
    clearElements();
    clearElementModalities();
    clearElementLinks();
    clearProjectUsers();
    clearTeams();
    clearInclusions();
    clearProjectEntries();

    try {
      const { fetchTeams } = this.props;
      await Promise.all([
        this.loadProject(forceProjectLoading),
        fetchTeams(),
      ]);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ projectLoading: false });
    }
  };

  loadProject = async (forceProjectLoading = false) => {
    if (forceProjectLoading || !this.props.project) {
      try {
        const { admin, fetchProject } = this.props;
        await fetchProject({ admin });
        const inclusionMode = ProjectView.getInitialMode(this.props);
        this.setState({ inclusionMode });
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, false);
        Toast.error(this.props, 'error:error.cannot-retrieve-project');
        const baseUrl = `${this.props.admin ? '/admin' : '/dashboard'}`;
        history.push(`${baseUrl}/projects`);
      } finally {
        this.setState({ projectLoading: false });
      }
    }
  };

  reload = async () => {
    this.loadData();
  }

  patchProject = async (key, value, extraParams = {}) => {
    const patch = {};
    patch[key] = value;
    const params = { ...extraParams };
    params.admin = this.props.admin;
    return this.props.patchProject(patch, params);
  };

  saveInputChange = async (input, name, format = null, key = 'value', extraParams = {},
    handleError = true, forceNullIfEmpty = true) => (
    new Promise((resolve, reject) => {
      const value = DomUtil.getValueFromTarget(input, format, key, forceNullIfEmpty);
      this.timeoutHandler.doAfterTimeout(async () => {
        try {
          const { admin } = this.props;
          await this.patchProject(name, value, { ...extraParams, admin });
          Toast.success(this.props, 'error:valid.saved');
          resolve();
        } catch (error) {
          if (handleError) {
            ErrorUtil.handleCatched(this.props, error);
            resolve();
          } else {
            reject(error);
          }
        }
      }, input.id);
    })
  );

  saveTopics = async (topics) => (
    new Promise((resolve, reject) => {
      this.timeoutHandler.doAfterTimeout(async () => {
        try {
          await this.patchProject('topics', topics.map((topic) => topic.value));
          Toast.success(this.props, 'error:valid.saved');
          resolve();
        } catch (error) {
          ErrorUtil.handleCatched(this.props, error);
          reject(error);
        }
      }, 'topics');
    })
  );

  onSharingBtnClick = () => {
    const { match, location } = this.props;
    const { url } = match;
    if (!location.pathname.endsWith('details')) {
      history.push(`${url}/details`);
      this.setState({
        scrollToInvitations: true,
        scrollEventCallback: () => this.setState({ scrollToInvitations: false }),
      });
    } else if (this.projectSettings) {
      this.projectSettings.scrollToInvitations();
    }
  }

  onLinkToStatusClick = () => {
    this.setState({
      scrollToProjectStatus: true,
      scrollEventCallback: () => this.setState({ scrollToProjectStatus: false }),
    });
  }

  renderSwitchToTestMode = (canChangeProjectStatus, projectOnHold, isOwnerOnly) => {
    const { t, match } = this.props;
    const { inclusionMode } = this.state;
    const { url } = match;
    let tooltipContent;

    if (projectOnHold) {
      if (canChangeProjectStatus) {
        tooltipContent = (
          <span>
            {t('project:enable-inclusions')}
            <br />
            <Link
              to={`${url}/details`}
              onClick={this.onLinkToStatusClick}
              className="text-white"
              style={{ textDecoration: 'underline' }}
            >
              {t('project:enable-inclusions-link')}
            </Link>
            <br />
            <FAQLink
              linkClassName="classical-link text-white"
              articleId={2}
            />
          </span>
        );
      } else {
        tooltipContent = (
          <span>
            {t('project:only-test-inclusions')}
          </span>
        );
      }
    } else {
      tooltipContent = t(
        inclusionMode === TEST_MODE
          ? 'project:activate-inclusion-mode'
          : 'project:activate-test-mode',
      );
    }

    return (
      <div className="col-auto">
        <div className="row align-items-center">
          {inclusionMode === TEST_MODE && (
            <div className="col-auto">
              <NewTooltip content={(<Trans i18nKey="error:warning.test-data" />)}>
                <FontAwesomeIcon
                  icon={['fas', 'exclamation-triangle']}
                  transform="grow-2"
                  className="text-newyellow-1 mt-1"
                />
              </NewTooltip>
            </div>
          )}
          <NewTooltip
            content={tooltipContent}
            interactive={projectOnHold && canChangeProjectStatus}
          >
            <div className="col-auto custom-control custom-switch pr-1">
              <input
                className="custom-control-input to-testing-mode-input"
                type="checkbox"
                checked={inclusionMode === TEST_MODE}
                onChange={async (e) => {
                  const newMode = e.target.checked ? TEST_MODE : NORMAL_MODE;
                  await this.setState({ inclusionMode: newMode });
                }}
                id="switch-to-testing-mode-input"
                disabled={projectOnHold || isOwnerOnly}
              />
              <label
                className="custom-control-label"
                htmlFor="switch-to-testing-mode-input"
              />
            </div>
          </NewTooltip>
          <div className="col-auto px-0">
            <Label className="align-center mb-0">
              {inclusionMode === TEST_MODE ? (
                t('project:test-mode')
              ) : (
                t('project:switch-to-test-mode')
              )}
            </Label>
          </div>
          <div className="col-auto">
            <Help
              interactive
              tooltipTheme="dark"
              iconClassName="ml-2"
              transform="down-1"
              className="align-center"
            >
              <FAQLink
                articleId={2}
                text={t('common:discover-feature')}
                linkClassName="classical-link small text-white"
              />
            </Help>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      t, user, admin, project, hideHeader, match, projectUser,
    } = this.props;
    const {
      scrollToInvitations, scrollEventCallback, scrollToProjectStatus, inclusionMode,
    } = this.state;

    const { url } = match;
    const isProjectFrozen = !project || isProjectDisabled(project);
    const canEditFormAndDocumentations = admin || (projectUser && !isProjectFrozen
      && hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS));
    const canViewFormAndDocs = projectUser && hasPermission(projectUser,
      CAN_VIEW_FORM_AND_DOCUMENTATIONS);

    const canIncludeData = hasPermission(projectUser, CAN_INCLUDE);
    const canEditFormAndDocs = hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS);
    const canIncludeTestData = hasPermission(projectUser, CAN_INCLUDE_TEST_DATA);

    const canAddTestInclusions = (project && project.status === PROJECT_ON_HOLD)
      ? canEditFormAndDocs || canIncludeTestData
      : canEditFormAndDocs || canIncludeTestData || canIncludeData;

    const isOwnerOnly = project && projectUser && project.owner.id === projectUser.user.id
      && projectUser.type === PROJECT_USER_OWNER_ONLY;

    const canSeeSwitchToTestMode = (!isProjectFrozen || isOwnerOnly) && projectUser
      && canAddTestInclusions;

    const canViewExternalInclusions = projectUser
      && hasPermission(projectUser, CAN_VIEW_EXTERNAL_INCLUSIONS);
    const canInclude = !admin;
    const canViewProjectResults = projectUser
      && hasPermission(projectUser, CAN_VIEW_PROJECT_RESULTS);

    const canDisplay = isBrowserView();

    const projectOnHold = project && project.status === PROJECT_ON_HOLD;
    const canChangeProjectStatus = projectUser && hasPermission(projectUser,
      CAN_CHANGE_PROJECT_STATUS);

    return (
      <Page
        {...this.props}
        id="project"
        title={`${t('project:project')}${project ? ` - ${project.name}` : ''}`}
      >
        {
            this.state.projectLoading ? (
              <CardLoader />
            ) : null
          }
        {
          !hideHeader && (
            <ProjectHead
              admin={admin}
              project={project}
              user={user}
              projectUser={projectUser}
              onShareToExternalTeam={this.onSharingBtnClick}
            />
          )
          }
        {
            project ? (
              <div className="dashboard-content mt-0">
                <div className="mb-3">
                  <MixedView>
                    <BigMenu {...this.props}>
                      {
                        canDisplay ? [
                          <BigMenuItem key={1} to={`${url}/details`} {...this.props}>
                            {t('project:nav.settings')}
                          </BigMenuItem>,
                          <BigMenuDivider key={2} />,
                          <BigMenuItem key={3} to={`${url}/resources`} {...this.props}>
                            {t('project:nav.resources')}
                          </BigMenuItem>,
                        ] : null
                      }
                      {
                        canDisplay && (canEditFormAndDocumentations || canViewFormAndDocs) ? [
                          <BigMenuDivider key={4} />,
                          <BigMenuItem key={5} to={`${url}/pages`} {...this.props}>
                            {t('project:nav.form')}
                          </BigMenuItem>,
                        ] : null
                      }
                      {
                        canDisplay && (canEditFormAndDocumentations || canViewFormAndDocs) ? [
                          <BigMenuDivider key={6} />,
                          <BigMenuItem key={7} to={`${url}/notifications`} {...this.props}>
                            {t('project:nav.notifications.title')}
                          </BigMenuItem>,
                        ] : null
                      }
                      {
                        canInclude && [
                          canDisplay ? <BigMenuDivider key={8} /> : null,
                          <BigMenuItem key={9} to={`${url}/inclusions`} {...this.props}>
                            {t('project:nav.inclusions')}
                          </BigMenuItem>,
                        ]
                      }
                      {
                        !admin && canViewProjectResults && canDisplay ? [
                          <BigMenuDivider key={11} />,
                          <BigMenuItem key={12} to={`${url}/results`} {...this.props}>
                            {t('project:nav.results')}
                          </BigMenuItem>,
                          <UpdatedBadge key={10} className="csv-export-badge">
                            <span />
                          </UpdatedBadge>,
                        ] : null
                      }
                    </BigMenu>
                  </MixedView>
                  <MobileView>
                    <BigMenu {...this.props}>
                      {
                        canInclude && [
                          <BigMenuItem key={1} to={`${url}/notifications`} {...this.props}>
                            {t('project:nav.notifications.title')}
                          </BigMenuItem>,
                        ]
                      }
                      {
                        canInclude && [
                          <BigMenuItem key={2} to={`${url}/inclusions`} {...this.props}>
                            {t('project:nav.inclusions')}
                          </BigMenuItem>,
                        ]
                      }
                      {
                        !admin ? [
                          <BigMenuDivider key={3} className="d-none" />,
                          <BigMenuItem key={4} to={`${url}/results`} {...this.props}>
                            {t('project:nav.results')}
                          </BigMenuItem>,
                          <UpdatedBadge key={10} className="csv-export-badge">
                            <span />
                          </UpdatedBadge>,
                        ] : null
                      }
                    </BigMenu>
                  </MobileView>
                </div>
                <Switch>
                  <Route
                    {...this.props}
                    path={`${url}/details`}
                  >
                    <div className="card bg-white card-shadow p-4 mobile-card">
                      <ContentHeader>
                        {t('project:nav.settings')}
                      </ContentHeader>
                      <BrowserView>
                        <ProjectSettings
                          {...this.props}
                          project={project}
                          saveInput={this.saveInputChange}
                          saveTopics={this.saveTopics}
                          scrollToInvitations={scrollToInvitations}
                          scrollToProjectStatus={scrollToProjectStatus}
                          ref={(ref) => { this.projectSettings = ref; }}
                          scrollEventCallback={scrollEventCallback}
                        />
                      </BrowserView>
                      <TabletUnsupported />
                      <MobileUnsupported>
                        common:mobile-and-tablet-unsupported
                      </MobileUnsupported>
                    </div>
                  </Route>
                  <Route
                    {...this.props}
                    path={`${url}/pages`}
                  >
                    <div className="card bg-white card-shadow p-4 mobile-card">
                      <BrowserView>
                        <ProjectForms {...this.props} project={project} />
                      </BrowserView>
                      { isBrowserView() ? null : (
                        <ContentHeader className="font-weight-normal mb-3">
                          {t('project:nav.form')}
                        </ContentHeader>
                      )}
                      <TabletUnsupported />
                      <MobileUnsupported>
                        common:mobile-and-tablet-unsupported
                      </MobileUnsupported>
                    </div>
                  </Route>
                  <Route
                    {...this.props}
                    path={`${url}/resources`}
                  >
                    <div className="card bg-white card-shadow p-4 mobile-card">
                      <ContentHeader>
                        {t('project:nav.resources')}
                      </ContentHeader>
                      <BrowserView>
                        <ProjectResources
                          {...this.props}
                          project={project}
                          saveInput={this.saveInputChange}
                        />
                      </BrowserView>
                      <TabletUnsupported />
                      <MobileUnsupported>
                        common:mobile-and-tablet-unsupported
                      </MobileUnsupported>
                    </div>
                  </Route>
                  <Route
                    {...this.props}
                    path={`${url}/notifications`}
                  >
                    <div className="card bg-white card-shadow p-4 mobile-card">
                      <ContentHeader subtitle={t('project:nav.notifications.subtitle')}>
                        {t('project:nav.notifications.title')}
                      </ContentHeader>
                      <BrowserView>
                        <ProjectNotifications
                          {...this.props}
                          admin={admin}
                          project={project}
                        />
                      </BrowserView>
                      <TabletUnsupported />
                      <MobileUnsupported>
                        common:mobile-and-tablet-unsupported
                      </MobileUnsupported>
                    </div>
                  </Route>
                  <Route
                    {...this.props}
                    path={`${url}/inclusions`}
                  >
                    <div className="card bg-white card-shadow p-4 mobile-card">
                      <div className={`row mb-3 justify-content-between align-items-center ${isMobileView() ? 'mx-1' : ''}`}>
                        <div className="col-auto d-flex align-items-center">
                          <h4 className="font-weight-normal mb-0 mr-2">
                            {t('inclusion:project-list-title')}
                          </h4>
                          {
                            canViewExternalInclusions && inclusionMode !== TEST_MODE && (
                              <div className="col-auto px-0">
                                <AuditTrailModal
                                  {...this.props}
                                  title={`${project ? `${project.name} - ` : ''}${t('project:audit-trail.title')}`}
                                  showInclusion
                                  showModule
                                  showVariable
                                >
                                  <NewTooltip
                                    content={(
                                      <div>
                                        <div>
                                          {t('project:audit-trail.icon-tooltip.project')}
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <FAQLink
                                            linkClassName="classical-link small text-white"
                                            text={t('common:discover')}
                                            articleId={10}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    interactive
                                  >
                                    <button
                                      type="button"
                                      className="no-button-style text-gray-dark pl-0"
                                    >
                                      <FontAwesomeIcon
                                        icon={['fas', 'history']}
                                        transform="grow-3"
                                      />
                                    </button>
                                  </NewTooltip>
                                </AuditTrailModal>
                              </div>
                            )
                          }
                        </div>
                        {canSeeSwitchToTestMode && this.renderSwitchToTestMode(
                          canChangeProjectStatus,
                          projectOnHold,
                          isOwnerOnly,
                        )}
                      </div>
                      <ProjectInclusions
                        {...this.props}
                        project={project}
                        showTestInclusions={inclusionMode === TEST_MODE}
                        resetInclusionMode={this.resetInclusionMode}
                      />
                    </div>
                  </Route>
                  <Route
                    {...this.props}
                    path={`${url}/results`}
                  >
                    <div>
                      <BrowserView>
                        <ProjectResults
                          {...this.props}
                        />
                      </BrowserView>
                      <TabletUnsupported />
                      <MobileUnsupported>
                        common:mobile-and-tablet-unsupported
                      </MobileUnsupported>
                    </div>
                  </Route>
                </Switch>
              </div>
            ) : null
          }
      </Page>
    );
  }
}


export default ProjectView;
